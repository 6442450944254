
import { computed, defineComponent, ref } from 'vue'
import SearchBar from '@/components/molecules/SearchBar.vue'
import PerPageItemsComp from '@/components/atom/PerPageItemsComp.vue'
import Pagination from '@/components/atom/Pagination.vue'
import { useRoute, useRouter } from 'vue-router'
import type { dossiersType } from '@/types/dossiers'
import dossiersApi from '@/api/dossiersApi'
import useSlugify from '@/utils/slugify'
import qs from 'qs'
import _ from 'lodash'
export default defineComponent({
  name: 'Home',
  components: {
    SearchBar,
    PerPageItemsComp,
    Pagination
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const defaultPageSize = 10
    const perPageItems = ref(route.query.perPageItems ? +route.query.perPageItems : defaultPageSize)
    const currentPage = ref(route.query.currentPage ? +route.query.currentPage : 1)
    const keyword = ref(route.query.keyword ? route.query.keyword : '')
    const totalRecord = ref(100)

    const dossiersList = ref<dossiersType[]>([
      {
        prj_code: '',
        prj_naam: ''
      }
    ])
    const dossiersListAll = ref<dossiersType[]>([
      {
        prj_code: '',
        prj_naam: ''
      }
    ])
    const sortInfo = ref({
      column: route.query.column ? route.query.column : '',
      order: route.query.order ? route.query.order : ''
    })
    const totalPages = computed(() => {
      return Math.ceil(totalRecord.value / perPageItems.value)
    })
    const { slugify } = useSlugify()
    const getDossiers = async () => {
      const query = Object.keys(route.query).length > 0
        ? route.query
        : {}
      const res = await dossiersApi.getDossiers(qs.stringify(query))
      if (res && res.rows) {
        dossiersListAll.value = res.rows
        totalRecord.value = res.rows.length
      }
      dossiersList.value = sliceArray()
    }

    const updatePagination = async (value: number) => {
      let query = route.query
      query = Object.assign({}, query, { currentPage: value })
      router.push({ name: 'Main', query })
      currentPage.value = value
      dossiersList.value = sliceArray()
    }
    const perPageItemsChange = async (value: number) => {
      let query = route.query
      currentPage.value = 1
      query = Object.assign({}, query, { perPageItems: value, currentPage: 1 })
      router.push({ name: 'Main', query })
      dossiersList.value = sliceArray()
    }

    const onKeywordSearch = async (value: string) => {
      currentPage.value = 1
      const query = { ...route.query, keyword: value, type: /^\d+$/.test(value) ? 'code' : 'name' }
      router.push({ name: 'Main', query }).then(() => {
        getDossiers()
      })
    }

    const sliceArray = () => {
      return dossiersListAll.value.slice(
        (currentPage.value - 1) * perPageItems.value,
        perPageItems.value * currentPage.value
      )
    }
    const onClick = (dossier: dossiersType) => {
      const params = {
        name: slugify(dossier.prj_naam),
        id: dossier.prj_code,
        page: dossier.prj_naam
      }
      router.push({ name: 'File', params: params })
    }
    getDossiers()

    const sortRecord = _.debounce((column: string) => {
      if(sortInfo.value.column !== column){
        sortInfo.value.column = column
        sortInfo.value.order = ''
      }
      switch (sortInfo.value.order) {
        case '':
          sortInfo.value.order = 'asc'
          break
        case 'asc':
          sortInfo.value.order = 'desc'
          break
        case 'desc':
          sortInfo.value.order = ''
          break
      }
      const query = {...route.query, column: sortInfo.value.column, order: sortInfo.value.order}
      router.push({ name: 'Main', query }).then(() => {
        getDossiers()
      })
    }, 300)

    const getSortIcon = (column: string) => {
      if (sortInfo.value.column === column && sortInfo.value.order) {
        return require(`@/assets/icons/sort-${sortInfo.value.order}.svg`)
      } else {
        return require('@/assets/icons/sort.svg')
      }
    }
    return {
      dossiersList,
      perPageItems,
      perPageItemsChange,
      totalPages,
      updatePagination,
      currentPage,
      keyword,
      onKeywordSearch,
      totalRecord,
      slugify,
      onClick,
      sortRecord,
      getSortIcon
    }
  }
})
